/* eslint-disable custom-rules/avoid-process-env */

export const getEnvVariableUnsafe = (key: string): string | undefined => {
	let value: string | undefined = undefined;
	if (typeof window === "undefined" || window?.env === undefined) {
		value = process.env?.[key];
	} else {
		value = process.env?.[key] ?? window.env?.[key];
	}

	return value;
};

// Remix with vite doesn't accept process.env on the client
export const getEnvVariable = (key: string): string => {
	let value: string | undefined = getEnvVariableUnsafe(key);

	if (value === undefined || value === null) {
		throw new Error(`Environment variable ${key} not found`);
	}

	return value;
};
